import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import router from './routers'
import App from './App.vue'
import store from './store' 
import axios, {
	get
} from './http'
import {
  menus,pageUrl
} from "@/unit";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; 
Vue.use(ElementUI);
import '@/assets/style/style.css';
Vue.prototype.axios = axios
Vue.prototype.get = get
Vue.prototype.imgHOST = axios.defaults.imgURL
Vue.prototype.$menus = menus
Vue.prototype.$pageUrl = pageUrl

Vue.config.productionTip = false

Vue.use(VueMeta, { 
  refreshOnceOnNavigation: true
})
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')