import Vue from 'vue'
import Router from 'vue-router'
import * as Lockr from "lockr";
import Layout from '@/components/layout'
Vue.use(Router)


const routers = [{
	path: "/",
	component: Layout,
	children: [{
		path: "/",
		component: (resolve) => require(['@/views/index'], resolve)
	}, {
		path: "news",
		component: (resolve) => require(['@/views/news'], resolve)
	},{
		path: "list",
		component: (resolve) => require(['@/views/list'], resolve)
	}, {
		path: "culture",
		component: (resolve) => require(['@/views/culture'], resolve)
	},
	{
		path: "about",
		component: (resolve) => require(['@/views/about'], resolve)
	}, {
		path: "org",
		component: (resolve) => require(['@/views/org'], resolve)
	}, {
		path: "team",
		component: (resolve) => require(['@/views/team'], resolve)
	},{
		path: "honor",
		component: (resolve) => require(['@/views/honor'], resolve)
	},{
		path: "idea",
		component: (resolve) => require(['@/views/idea'], resolve)
	},{
		path: "pdetail",
		component: (resolve) => require(['@/views/pdetail'], resolve)
	},{
		path: "projects",
		component: (resolve) => require(['@/views/projects'], resolve)
	},{
		path: "dangjian",
		component: (resolve) => require(['@/views/dangjian'], resolve)
	},{
		path: "dlist",
		component: (resolve) => require(['@/views/dlist'], resolve)
	}, {
		path: "ddetail",
		component: (resolve) => require(['@/views/ddetail'], resolve)
	},  {
		path: "detail",
		component: (resolve) => require(['@/views/detail'], resolve)
	}, {
		path: "menu",
		component: (resolve) => require(['@/views/menudetail'], resolve)
	}, {
		path: "notice",
		component: (resolve) => require(['@/views/notice'], resolve)
	}, {
		path: "search",
		component: (resolve) => require(['@/views/search'], resolve)
	},{
		path: "links",
		component: (resolve) => require(['@/views/links'], resolve)
	}]
}, {
	path: "*",
	component: Layout,
	children: [{
		path: "/",
		component: (resolve) => require(['@/views/index'], resolve)
	}]
}, ]

const router = new Router({
	mode: 'history',
	scrollBehavior: () => ({
		y: 0
	}),
	routes: routers
})

router.beforeEach((to, from, next) => {
  next()
})

export default router