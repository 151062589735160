import store from '@/store'

export function menus(list, id) {
	let parent = {};
	let current = {};
	let subMenuArr = [];
	let index = 0;
	list.forEach(item => {
		if (item.menuId == id) {
			parent = item;
			current = item;
		}
		if (!parent.menuId) {
			item.children.forEach((ite,i) => {
				if (ite.menuId == id) {
					parent = item;
					current = ite;
					index = i
				}
			})
		}
	})
	let arr = []
	if(parent.children){ 
		parent.children.forEach(ite => {
			arr.push(ite.menuName)
		})
	}
	subMenuArr = arr;

	return {
		parent,
		current,
		index,
		subMenuArr
	}
}
 
export function pageUrl(item) {
	let menutype = parseInt(item.menuType)
	let id = item.menuId
	
	let url = '/'
	if (menutype == 1) {
		url = '/about?id=' + id
	} else if (menutype == 10) {
		url = '/notice?id=' + id 
	} else if (menutype == 11) {
		url = '/news?id=' + id
	} else if (menutype == 12) {
		url = '/list?id=' + id
	} else if (menutype == 20) {
		url = '/menu?id='+id
	} else if (menutype == 50) {
		url = '/about?id=' + id
	} else if (menutype == 51) {
		url = '/team?id=' + id
	} else if (menutype == 52) {
		url = '/org?id=' + id
	} else if (menutype == 53) {
		url = '/honor?id=' + id
	} else if (menutype == 70) {
		url = '/idea?id=' + id
	} else if (menutype == 60) { 
		url = '/dangjian?id=' + id
	} else if (menutype == 80) {
		url = '/projects?id=' + id
	} else if (menutype == 99) {
		return item.path
	} 
	 
	return url
}